// HomePage.js
import React, { useState } from "react";
import Header from "../components/header";

import '../css/common.scss'
import '../css/resume.scss'
// import '../css/homepage2.scss'
// import '../css/homepage3.scss'
// import '../css/homepage4.scss'
// import '../css/homepage5.scss'

import '../js/homeYusong.js'
import '../js/homeScroll.js'



const iconColor ="primary:#365a31"

const Resume = () => {

  return (
    <div className="page homepage">
      <Header/>
      <div className="headerSpace"></div>
      <div>
        <img src={require('../image/resume/resume1.jpg')} className="resume"/>
        <img src={require('../image/resume/resume2.jpg')} className="resume"/>
      </div>
      
    </div>
  );
};

export default Resume;
