// HomePage.js
import React, { useState } from "react";
import Button from '@mui/material/Button';
import Header from "../components/header";
import '../css/common.scss'
import '../css/homepage.scss'
import '../css/homepage2.scss'
import '../css/homepage3.scss'
import '../css/homepage4.scss'
import '../css/homepage5.scss'
// import '../js/homeimg.js';
import song from '../image/song1.png'
import '../js/homeYusong.js'
import '../js/homeScroll.js'

import {download} from '../js/downloadpdf.js'

import s1_1 from '../image/section1_1.png'
import s1_2 from '../image/section1_2.png'
import s1_3 from '../image/section1_3.png'

import { gsap } from "gsap";



const iconColor ="primary:#365a31"

const HomePage = () => {
  
  const [coreSkill,setCoreSkill] = useState([
    {
      title:'Program Languages',
      content:'JavaScript, HTML, CSS, Shell, Perl, Python, C, TypeScript'
    },{
      title:'Frontend Frameworks',
      content:'VueJS, ReactJS, Angular10, Bootstrap, Material UI'
    },{
      title:'Database Servers',
      content:'SQL, MongoDB'
    },{
      title:'Testing',
      content:'Jasmine(Angular10), Enzyme(ReactJS), ESLint'
    },{
      title:'Others',
      content:'Docker, Nginx, AWS(EC2)'
    }
  ])

  const [othersides,setOthersides] = useState([
    {
      // title:'Teavel',
      content:'',
      img:'',
      location:'',
      index:2,
    },{
      // title:'Teavel',
      content:'',
      img:'',
      location:'',
      index:1,
    },{
      // title:'Teavel',
      content:'',
      img:'',
      location:'',
      index:3,
    },{
      // title:'Teavel',
      content:'',
      img:'',
      location:'',
      index:6,
    },{
      // title:'Teavel',
      content:'',
      img:'',
      location:'',
      index:7,
    },{
      // title:'Teavel',
      content:'',
      img:'',
      location:'',
      index:8,
    },{
      // title:'Teavel',
      content:'',
      img:'',
      location:'',
      index:10,
    },{
      // title:'Teavel',
      content:'',
      img:'',
      location:'',
      index:11,
    },{
      // title:'Teavel',
      content:'',
      img:'',
      location:'',
      index:12,
    },{
      // title:'Teavel',
      content:'',
      img:'',
      location:'',
      index:13,
    },
  ])

  const handleCall=()=>{
    window.open('tel:0439732990')
  }

  const handleEmail=()=>{
    window.open('mailto:c7ls32@gmail.com')
  }

  const goBack=()=>{
    gsap.to(window, { duration: 1, scrollTo: { y: 0 } });
  }
  
  const downloadResume=()=>{
    download()
  }

  return (
    <div className="page homepage">
      <Header/>
      <div className="headerSpace"></div>
      <section id="section_1">
        <div className="mainpage">
          <div className="pagecenter">
            <div className="frame" >
            {/* id="frame" */}
              <div className="imgContainer">
                <img src={song} className="song1" alt="song"/>
              </div>
            </div>
            <div className="text">
              <h1 className="bigTitle">Hi, My</h1>
              <h1 className="bigTitle flowText">name is &nbsp;
              <span>Y</span>
              <span>U</span>
              <span>&nbsp;</span>
              <span>S</span>
              <span>O</span>
              <span>N</span>
              <span>G</span>
              <span>.</span>
              {/* <span>YU SONG</span>. */}
              </h1>
              <h3>I am a Frontend Developer, Based in Sydney, Australia</h3>
              <Button variant="contained" onClick={downloadResume}>Download Resume</Button>
            </div>
          </div>
          {/* <div className="section1_pic">
            <img src={s1_1} className="s1_1" alt="sydney1"/>
            <img src={s1_2} className="s1_2" alt="sydney2"/>
            <img src={s1_3} className="s1_3" alt="sydney3"/>
          </div> */}
        </div>
      </section>
      <section id="section_2">
        <div className="mainpage2">
          <div className="pagecenter2">
            <h1 className="whychooseyusong">Why Choose Yu Song</h1>
            <div className="blocks">
              <div className="block" id="whychooseyusong1">
                <lord-icon
                    src="https://cdn.lordicon.com/lzgmgrnn.json"
                    trigger="hover"
                    colors={iconColor}
                    style={{'width':'40px','height':'40px'}}>
                </lord-icon>
                <div className="highlightTitle">
                Expert Coding
                </div>
                <div className="highlightContect">
                  Producing high-quality, clean, and efficient code with reusable functions.
                </div>
              </div>
              <div className="block" id="whychooseyusong2">
                <lord-icon
                    src="https://cdn.lordicon.com/xmuplryc.json"
                    trigger="hover"
                    colors={iconColor}
                    style={{'width':'40px','height':'40px'}}>
                </lord-icon>
                <div className="highlightTitle">
                Responsive Design
                </div>
                <div className="highlightContect">
                Guaranteeing seamless experiences across all devices by employing responsive design principles and optimizing performance.
                </div>
              </div>
              <div className="block" id="whychooseyusong3">
                <lord-icon
                  src="https://cdn.lordicon.com/aklfruoc.json"
                  trigger="hover"
                  colors={iconColor}
                  style={{'width':'40px','height':'40px'}}>
                </lord-icon>
                <div className="highlightTitle">
                Innovative Solutions
                </div>
                <div className="highlightContect">
                Continuously exploring and seeking out new objectives to pursue.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="section_4">
        <div className="mainpage4">
          <div className="pagecenter4">
            <h1 className="corepart">Core Skills</h1>
            <div className="corepartblocks">
              {coreSkill.map((data, index) => (
                <div className="corepartblock" id="" key={index}>
                  <div className="highlightTitle">
                  {data.title}
                  </div>
                  <div className="highlightContect">
                  {data.content}
                  </div>
                </div>
              ))}
              
            </div>
          </div>
        </div>
      </section>
      <section id="section_3">
        <div className="mainpage3">
          <div className="pagecenter3">
            <h1>My Other Side</h1>
            <div className="blocks">
              {othersides.map((data, index) => (
                <div className="coreSkillBlock" id="" key={index}>
                  <img src={require(`../image/travel/${data.index}.jpg`)} alt="otherPic" className="othersideImg"/>
                </div>
              ))}
              
            </div>
          </div>
        </div>
      </section> 
      <section id="section_5">
        <div className="mainpage5">
          <div className="pagecenter5">
            <h1>Get In Touch</h1>
            <div className="touchblocks">
              <div className="touchblock" onClick={handleCall}>
                <div>
                  <lord-icon
                      src="https://cdn.lordicon.com/rsvfayfn.json"
                      trigger="hover"
                      colors={iconColor}
                      style={{'width':'40px','height':'40px'}}>
                  </lord-icon>
                </div>
                <h3>+61 439732990</h3>
              </div>
            </div>
            <div className="touchblocks">
              <div className="touchblock" onClick={handleEmail}>
                <div>
                  <lord-icon
                      src="https://cdn.lordicon.com/nzixoeyk.json"
                      colors={iconColor}
                      trigger="hover"
                      style={{'width':'40px','height':'40px'}}>
                  </lord-icon>
                </div>
                <h3>c7ls32@gmail.com</h3>
              </div>
            </div>
            <Button className="touchButton" variant="contained" onClick={downloadResume}>Download Resume</Button>
          </div>
          <div onClick={goBack} className="backtotop">
            Back to the top
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomePage;
