
export function download(){
    // const pdfPath = '/Users/song/Desktop/profile_ys/profile_ys/yusong/src/files/YuSong_Resume.pdf';
    const pdfPath=process.env.PUBLIC_URL + '/YuSong_Resume.pdf'
    // 创建一个隐藏的 a 标签
    const link = document.createElement('a');
    link.href = pdfPath;
    link.setAttribute('download', 'YuSong_Resume.pdf'); // 设置下载的文件名
    
    // 将 a 标签添加到文档中
    document.body.appendChild(link);
    
    // 触发点击事件
    link.click();
    
    // 点击后从文档中移除 a 标签
    document.body.removeChild(link);
}

    
