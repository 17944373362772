window.onload = function() {
    const spans = document.querySelectorAll('.flowText span');
    spans.forEach((span, idx) => {
      span.addEventListener('mouseover', (e) => {
        e.target.classList.add('active');
      });
      span.addEventListener('animationend', (e) => {
        e.target.classList.remove('active');
      });
      
      // Initial animation
      setTimeout(() => {
        span.classList.add('active');
      }, 750 * (idx+1))
    });
}
