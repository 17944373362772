// HomePage.js
// import React, { useState } from "react";
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { styled } from '@mui/material/styles';
import { createMuiTheme,createTheme, ThemeProvider } from '@mui/material/styles';
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import '../css/header.scss'
import { useNavigate } from "react-router-dom";
// import { createTheme, ThemeProvider } from '@mui/material/styles';

import {download} from '../js/downloadpdf.js'
gsap.registerPlugin(ScrollToPlugin);

// import {meau} from '../image/meau.svg'

const iconColor ="primary:#121331,secondary:#365a31"
const actions = [
    { src: 'https://cdn.lordicon.com/laqlvddb.json', name: 'Home' ,url:'/'},
    { src: 'https://cdn.lordicon.com/xynjytfp.json', name: 'Download Resume' ,url:'/resume'},
    // { src: 'https://cdn.lordicon.com/pbbsmkso.json', name: 'Travel Story' },
    // { src: 'https://cdn.lordicon.com/xytaicoa.json', name: 'Dive log' },
    { src:'https://cdn.lordicon.com/xkixzgap.json',  name:'Contact Me',url:'/contactme'}
];

const theme = createTheme({
    palette: {
      primary : {
        main: '#ffffff',
        // contrastText: 're',
      },
    },
  });
  
const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
    position: '',

  }));

function backtoTop (){
    gsap.to(window, { duration: 1, scrollTo: { y: 0 } });
}

const variable_name = './YuSong_Resume.pdf'
const Header = () => {
    const goRouter = (index) => {
        if(index==0){
            backtoTop()
        }else if(index==1){
            download()
        }else{
            window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' });
        }
    }
  return (
    
    <div className="header">
        <h2 onClick={() => backtoTop()} className='websiteName'>
            YUSONG.
        </h2>
            <ThemeProvider theme={theme}>
                <Box sx={{ mt: 3, height: 320 }} >
                    <StyledSpeedDial
                    ariaLabel="meau"
                    icon={<lord-icon
                        src="https://cdn.lordicon.com/uecgmesg.json"
                        trigger="hover"
                        colors={iconColor}
                        style={{'width':'50px','height':'50px'}}>
                        </lord-icon>}
                    direction='down'
                    >
                    {actions.map((action,index) => (
                        <SpeedDialAction
                        onClick={()=>goRouter(index)}
                        key={action.name}
                        icon={
                            <lord-icon
                                src={action.src}
                                trigger="hover"
                                colors={iconColor}
                                style={{'width':'30px','height':'30px'}}>
                            </lord-icon>
                        }
                        tooltipTitle={action.name}
                        TooltipClasses={{
                            color: "#ffffff",
                            backgroundColor:'#ffffff'
                        }}
                        />
                    ))}
                    </StyledSpeedDial>
                </Box>
            </ThemeProvider>
    </div>
  );
};

export default Header;
