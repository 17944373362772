import Lenis from 'lenis'
import { gsap } from "gsap";
import { ReactLenis, useLenis } from 'lenis/react'
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Register the ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);


const lenis = new Lenis({
    duration: 1.2,
    easing:(t) => Math.min(1,1.001 - Math.pow(2,-10*t))
});

function raf(time){
    lenis.raf(time);
    ScrollTrigger.update();
    requestAnimationFrame(raf);
}

requestAnimationFrame(raf);

window.onload = function() {
    //section1
    const section_1 = document.getElementById('section_1')
    const frame=document.querySelector('.imgContainer')
    const timein = gsap.timeline()
    
    timein.from(frame, { opacity: 0, y: '90', duration: 2 });
    
    // const fix_pagecenter = gsap.timeline()
    // fix_pagecenter.to('.pagecenter',{y:'100vh', scrub: true })
    // const fix_pagecenter_create=ScrollTrigger.create({
    //     animation:fix_pagecenter,
    //     trigger:'.pagecenter',
    //     start:'top top',
    //     end:'bottom+=1000 bottom',
    //     scrub:1,
    //     pin:true,
    // })

    // const section1_1_time = gsap.timeline()
    // section1_1_time.fromTo('.s1_1', { opacity: 0, x:'60vw',y:'30vh' }, { rotation: 160,opacity: 1, duration: 1,x:'0',y:'5vh' },0)
    // .fromTo('.s1_2', { opacity: 0,x:'-30vw',y:'-10vh' }, { rotation: 20,opacity: 1, delay:0.2,duration: 0.5,x:'0vw',y:'-5vh' },0)
    // .fromTo('.s1_3', { opacity: 0,x:'0',y:'10vh'}, { rotation: -30,opacity: 1,duration: 0.5 ,x:'0',y:'-5vh'},0);
    // // .to('.pagecenter',{y:'100vh'},0);

    // const section1_1_create=ScrollTrigger.create({
    //     animation:section1_1_time,
    //     trigger:'.pagecenter',
    //     start:'top 20%',
    //     end:'bottom center',
    //     scrub:1,
    // })

    // const section1_gone=document.querySelector('.mainpage')
    // const section1_gone_time = gsap.timeline()
    // section1_gone_time.fromTo(section1_gone, {opacity: 1}, { delay:10,opacity: 0, duration: 0.5 ,y:'-5vh'});

    // const section1_gone_set=ScrollTrigger.create({
    //     animation:section1_gone_time,
    //     trigger:section_1,
    //     start:'bottom bottom',
    //     end:'bottom',
    //     scrub:'true',
    // })

    //section2
    const section_2 = document.getElementById('section_2')
    const choosepart=document.querySelector('.whychooseyusong')
    const chooseparttime = gsap.timeline()
    chooseparttime.fromTo(choosepart, { opacity: 0,y:'30vh' }, {opacity: 1, duration: 0.5,y:'0' });
    chooseparttime.fromTo(".blocks", { opacity: 0,y:'10vh' },{ opacity: 1,y:'0',duration: 0.5,delay:0.1})
    // .fromTo("#whychooseyusong2", { opacity: 0,y:'10vh' },{opacity: 1, y:'0',duration: 0.5,delay:0.2},0)
    // .fromTo("#whychooseyusong3", { opacity: 0,y:'10vh' },{opacity: 1,y:'0',duration: 0.5,delay:0.3},0);

        
    const section_2_create=ScrollTrigger.create({
        animation:chooseparttime,
        trigger:section_1,
        start:'bottom bottom',
        end:'bottom',
        scrub:1,
        // markers:true,
    })

    const section2_gone=document.querySelector('.mainpage2')
    const section2_gone_time = gsap.timeline()
    section2_gone_time.fromTo(section2_gone, {opacity: 1}, { opacity: 0, duration: 0.5 ,y:'-5vh'});

    const section2_gone_set=ScrollTrigger.create({
        animation:section2_gone_time,
        trigger:section_2,
        start:'bottom bottom',
        end:'bottom',
        scrub:'true',
    })

    //section4
    const section_4 = document.getElementById('section_4')
    const corepart=document.querySelector('.corepart')
    const coreparttime = gsap.timeline()
    coreparttime.fromTo(corepart, { opacity: 0,y:'30vh' }, {opacity: 1, duration: 0.5,y:'0' });
    // coreparttime.fromTo(".corepartblocks", { opacity: 0,y:'10vh' },{ opacity: 1,y:'0',duration: 0.5,delay:0.1},0)
        
    const section_4_create=ScrollTrigger.create({
        animation:coreparttime,
        trigger:section_2,
        start:'bottom bottom',
        end:'bottom',
        scrub:1,
    })

    const section4_gone=document.querySelector('.mainpage4')
    const section4_gone_time = gsap.timeline()
    section4_gone_time.fromTo(section4_gone, {opacity: 1}, { opacity: 0, duration: 0.5 ,y:'-5vh'});

    let core_items = gsap.utils.toArray(".corepartblock");
    core_items.forEach((box,index) => {
        gsap.fromTo(box, 
            { opacity: 0,y:'10vh' }, // 起始状态
            { 
                y: 0, 
                opacity: 1, 
                duration: 1, 
                scrollTrigger: {
                    trigger: box,
                    start: 'top 80%',
                    end: 'top 30%',
                    scrub: true,
                    // markers: true,
                }
            });
            
        gsap.to(box, 
            { 
                x:Math.pow(-1, index+1)*1000,
                // opacity: 0, 
                duration: 1, 
                scrollTrigger: {
                trigger: box,
                start: 'top 20%',
                end: 'top top',
                scrub: true,
                // markers: true,
                }
            })
    });
    // let core_items = gsap.utils.toArray(".corepartblock");
    // core_items.forEach((box,index) => {
    //     gsap.fromTo(box, 
    //        { opacity: 0,y:'' }, // 起始状态
    //        { 
    //          x:Math.pow(-1, index+1)*1000,
    //          opacity: 0, 
    //          duration: 1, 
    //          scrollTrigger: {
    //            trigger: box,
    //            start: 'top 20%',
    //            end: 'top top',
    //            scrub: true,
    //            markers: true,
    //          }
    //        })
    //    });

    // core_items.forEach((box,index) => {
    //     gsap.to(box, 
    //     { 
    //         x:Math.pow(-1, index+1)*1000,
    //         opacity: 0, 
    //         duration: 1, 
    //         scrollTrigger: {
    //         trigger: box,
    //         start: 'top 20%',
    //         end: 'top top',
    //         scrub: true,
    //         markers: true,
    //         }
    //     })
    // });
    // const section4_gone_set=ScrollTrigger.create({
    //     animation:section4_gone_time,
    //     trigger:section_4,
    //     start:'bottom bottom',
    //     end:'bottom bottom',
    //     scrub:'true',
    // })

    //section3
    const section_3 = document.getElementById("section_3");

    const section4_gone_set=ScrollTrigger.create({
        animation:section4_gone_time,
        trigger:section_3,
        start:'top top',
        end:'bottom center',
        scrub:'true',
    })

    let box_items = gsap.utils.toArray(".coreSkillBlock");

    gsap.to(box_items, {
    xPercent: -100 * (box_items.length - 1),
    ease: "power1.inOut",
    scrollTrigger: {
        trigger: section_3,
        start:"top 10vh",
        pin: true,
        scrub: 3,
        snap: 1 / (box_items.length - 1),
        end: "+=" + section_3.offsetWidth
    }
    });

    //section5
    const section_5 = document.getElementById('section_5')
    const touchpart=document.querySelector('.mainpage5')
    const touchparttime = gsap.timeline()
    touchparttime.fromTo(touchpart, { opacity: 0,y:'30vh' }, {opacity: 1, duration: 0.5,y:'0' });
        
    const touchparttime_create=ScrollTrigger.create({
        animation:touchparttime,
        trigger:section_3,
        start:'bottom bottom',
        end:'bottom',
        scrub:1,
    })

}