import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/homepage.jsx';
import Resume from './pages/resume.jsx';
import './css/router.scss'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Lenis from 'lenis'
import { gsap } from "gsap";
import { ReactLenis, useLenis } from 'lenis/react'
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);


const theme = createTheme({
  palette: {
    primary : {
      main: '#365a31',
      contrastText: '#fbf9ff',
    },
    // background:{
    //   default: "#e4f0e2"
    // }
  },
  // overrides: {
  //   MuiSpeedDialAction: {
  //     staticTooltipLabel: {
  //       backgroundColor : 'red',
  //       width : 150,
  //     },
  //   },
  // },
});

const App = () => {

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes> 
          <Route
            path="/"
            element={<HomePage />}
          />
          <Route
            path="/Resume"
            element={<Resume />}
          />
          {/* <Route
            path="/ContactUs"
            element={<ContactUs />}
          />
          <Route
            path="/AboutUs"
            element={<AboutUs />}
          /> */}
        </Routes>
      </Router>
    </ThemeProvider>
  );

};

export default App;
